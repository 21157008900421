import React, { Component } from 'react'
import styled from 'styled-components'
import './css/base.css'
import './css/header.css'
import './css/style.css'
import './css/navmenu.css'

const MenuItem = styled.span`
  font-family: "Roboto Condensed", sans-serif;
  font-size: 13.6px;
  font-weight: 300;
`

class Header extends Component {
  render() {
    return (
      <header class="header">
        <div class="desktop-header container">
            <div class="row">
                <nav aria-label="Primary menu" class="header-navigation">
                    <ul class="header-navigation-wrapper">
                        <li>
                          <a href="https://agoracles.io/#vision" aria-current="page">
                            <MenuItem>Our vision</MenuItem>
                          </a>
                        </li>
                        <li>
                          <a href="https://agoracles.io/#roadmap" aria-current="page">
                            <MenuItem>Roadmap</MenuItem>
                          </a>
                        </li>
                        <li>
                          <a href="https://agoracles.io/#faq" aria-current="page">
                            <MenuItem>FAQ</MenuItem>
                          </a>
                        </li>
                        <li>
                          <a href="https://agoracles.io/join-our-whitelist/">
                            <MenuItem>JOIN OUR WHITELIST</MenuItem>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <MenuItem>BUY</MenuItem>
                          </a>
                        </li>
                    </ul>
                </nav>
            </div>
        </div>
      </header>
    );
  }
}

export default Header;
