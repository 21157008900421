import React, { useState, useMemo, useEffect } from 'react'
import axios from 'axios'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup'
import ToggleButton from 'react-bootstrap/ToggleButton'
import { useActiveWeb3React } from './hooks'
import Web3ReactManager from './components/Web3ReactManager'
import styled from 'styled-components'
import { BigNumber } from '@ethersproject/bignumber'
import './App.css'
import Web3Status from './components/Web3Status'
import MintBar from './components/MintBar'
import { AGORA_BOARS_ADDRESS } from './config'
import Background from './assets/bg-intro.jpg'
import AgoraclesHeader from './components/Header'
import { useAgoraBoarsContract } from './hooks/useContract'
import { useSingleCallResult } from './state/multicall/hooks'

const ONE_ETHER = BigNumber.from('1000000000000000000')

const Header = styled.div`
  font-family: Roboto Condensed, sans-serif;
  font-weight: 700;
  font-size: 6em;
  text-transform: capitalize;
  color: #000000;
  margin-bottom: 25px;
`

const Regular = styled.div`
  font-family: Roboto Mono, monospace;
  font-size: 16px;
  font-weight: 400;
  color: #000000;
`

const RegularBold = styled(Regular)`
  font-size: 20px;
  font-weight: bold;
`

const ErrorText = styled(Regular)`
  font-weight: 600;
  margin-top: 25px;
`

const AppWrapper = styled(Container)`
  background: #000 url(${Background}) no-repeat center;
  background-size: cover;
  background-attachment: fixed;
  height: 100vh;
  width: 100%;
  max-width: 100%;
  margin: 45px 0 0 0;
  padding-top: 15%;
`

const TextWrapper = styled(Row)`
  background-color: #FFF15D;
  width: 100%;
  max-width: 100%;
  height: 100%;
  padding-left: 5%;
`

const WhitelistStatus = styled(Regular)`
  margin-top: 25px;
  margin-bottom: 25px;
`

const App = () => {
  const { account } = useActiveWeb3React()
  const [ error, setError ] = useState<string>("")
  const [ publicMintingOpen, setPublicMintingOpen ] = useState(false)
  const [ whitelistMintingOpen, setWhitelistMintingOpen ] = useState(false)
  const [ mintedNfts, setMintedNfts ] = useState<BigNumber>(BigNumber.from(0))
  const [ presaleMaxNfts, setPresaleMaxNfts ] = useState<BigNumber>(BigNumber.from(0))
  const [ maxNfts, setMaxNfts ] = useState<BigNumber>(BigNumber.from(0))
  const [ whitelistProof, setWhitelistProof ] = useState<string>("")
  const [ contractOwner, setContractOwner ] = useState<string>("")
  const [ whitelistAddresses, setWhitelistAddresses ] = useState<string[]>([])

  const agoraBoarsContract = useAgoraBoarsContract(AGORA_BOARS_ADDRESS, true)

  const presalePriceCall = useSingleCallResult(agoraBoarsContract, 'presalePrice')
  const presalePrice = useMemo(() => {
    if (presalePriceCall.result) return presalePriceCall.result[0]
    else return BigNumber.from(1).mul(ONE_ETHER).div(10)
  }, [presalePriceCall])

  const publicPriceCall = useSingleCallResult(agoraBoarsContract, 'publicPrice')
  const publicPrice = useMemo(() => {
    if (publicPriceCall.result) return publicPriceCall.result[0]
    else return BigNumber.from(15).mul(ONE_ETHER).div(10)
  }, [publicPriceCall])

  const mintedNftsCall = useSingleCallResult(agoraBoarsContract, 'totalSupply')
  useEffect(() => {
    if (!mintedNftsCall.loading && mintedNftsCall.result) {
      setMintedNfts(mintedNftsCall.result[0])
    }
  }, [mintedNftsCall])

  const presaleMaxNftsCall = useSingleCallResult(agoraBoarsContract, 'WLLIMIT')
  useEffect(() => {
    if (!presaleMaxNftsCall.loading && presaleMaxNftsCall.result) {
      setPresaleMaxNfts(presaleMaxNftsCall.result[0])
    }
  }, [presaleMaxNftsCall])

  const maxNftsCall = useSingleCallResult(agoraBoarsContract, 'MAXCOLLECTIONSIZE')
  useEffect(() => {
    if (!maxNftsCall.loading && maxNftsCall.result) {
      setMaxNfts(maxNftsCall.result[0])
    }
  }, [maxNftsCall])

  const publicMintingOpenCall = useSingleCallResult(agoraBoarsContract, 'isPublicMintingOpened')
  useEffect(() => {
    if (!publicMintingOpenCall.loading && publicMintingOpenCall.result) {
      setPublicMintingOpen(publicMintingOpenCall.result[0])
    }
  }, [publicMintingOpenCall])

  const whitelistMintingOpenCall = useSingleCallResult(agoraBoarsContract, 'isWhitelistMintingOpened')
  useEffect(() => {
    if (!whitelistMintingOpenCall.loading && whitelistMintingOpenCall.result) {
      setWhitelistMintingOpen(whitelistMintingOpenCall.result[0])
    }
  }, [whitelistMintingOpenCall])

  const ownerCall = useSingleCallResult(agoraBoarsContract, 'owner')
  useEffect(() => {
    if (!ownerCall.loading && ownerCall.result) {
      setContractOwner(ownerCall.result[0])
    }
  }, [ownerCall])

  useEffect(() => {
    const fetchData = async () => {
      try {
        const resp = await axios.get("https://api.boarsngods.io/agoraboars/wl/proof/" + account)
        setWhitelistProof(resp.data)
      } catch (error) {
        if (error.response && error.response.status === 400) {
          setWhitelistProof("")
        }
      }
    }

    if (account) {
      fetchData()
    }
  }, [account])

  useEffect(() => {
    const fetchData = async () => {
      try {
        const resp = await axios.get("https://api.boarsngods.io/agoraboars/wl/addresses")
        setWhitelistAddresses(resp.data)
      } catch (error) {
        if (error.response && error.response.status === 400) {
          setWhitelistAddresses([])
        }
      }
    }

    if (account === contractOwner) {
      fetchData()
    }
  }, [account, contractOwner])

  const handlePresaleMint = (numToMint: number, nftPrice: BigNumber) => {
    setError("")
    if (numToMint > 0 && whitelistProof.length !== 0) {
      const totalPrice = nftPrice.mul(numToMint)
      agoraBoarsContract?.merkleTreeWLMint(numToMint, whitelistProof, {
        from: account,
        value: totalPrice
      })
      .catch((error: any) => {
        console.log(error.code)
        if (error.code === 'INSUFFICIENT_FUNDS') {
          setError('Error: Insufficient funds')
        }
      })
    }
  }

  const handleMint = (numToMint: number, nftPrice: BigNumber) => {
    setError("")
    if (numToMint > 0) {
      const totalPrice = nftPrice.mul(numToMint)
      agoraBoarsContract?.mint(numToMint, {
        from: account,
        value: totalPrice
      })
      .catch((error: any) => {
        console.log(error.code)
        if (error.code === 'INSUFFICIENT_FUNDS') {
          setError('Error: Insufficient funds')
        }
      })
    }
  }

  const handleWhitelistMintingChange = (val: number) => {
    if ((val === 1 && !whitelistMintingOpen) || (val === 0 && whitelistMintingOpen)) {
      agoraBoarsContract?.flipWhiteListMint()
      .catch((error: any) => {
        console.log(error.code)
      })
    }
  }

  const handlePublicMintingChange = (val: number) => {
    if ((val === 1 && !publicMintingOpen) || (val === 0 && publicMintingOpen)) {
      agoraBoarsContract?.flipPublicMint()
      .catch((error: any) => {
        console.log(error.code)
      })
    }
  }

  return (
    <>
      <AgoraclesHeader/>
      <AppWrapper>
        <Web3ReactManager>
          <Container fluid>
            <TextWrapper>
              <Col>
                {contractOwner === account && (
                  <>
                    <Header>Admin Panel</Header>
                    <WhitelistStatus/>
                    <Row style={{display:'flex', justifyContent:'left', paddingLeft: '15px'}}>
                      <RegularBold>Presale</RegularBold>
                      <ToggleButtonGroup
                            style={{ paddingLeft: "10px" }}
                            type="radio"
                            name="radio"
                            onChange={handleWhitelistMintingChange}
                            defaultValue={whitelistMintingOpen ? 1 : 0}>
                        <ToggleButton
                            id="radio-1"
                            type="radio"
                            name="radio"
                            value={1}
                            variant="outline-success"
                            checked={whitelistMintingOpen}>
                          On
                        </ToggleButton>
                        <ToggleButton
                            id="radio-2"
                            type="radio"
                            name="radio"
                            value={0}
                            variant="outline-danger"
                            checked={!whitelistMintingOpen}>
                          Off
                        </ToggleButton>
                      </ToggleButtonGroup>
                      <RegularBold style={{paddingLeft: '15px'}}>Public Sale</RegularBold>
                      <ToggleButtonGroup
                            style={{ paddingLeft: "10px" }}
                            type="radio"
                            name="radio"
                            onChange={handlePublicMintingChange}
                            defaultValue={publicMintingOpen ? 1 : 0}>
                        <ToggleButton
                            id="radio-1"
                            type="radio"
                            name="radio"
                            value={1}
                            variant="outline-success"
                            checked={publicMintingOpen}>
                          On
                        </ToggleButton>
                        <ToggleButton
                            id="radio-2"
                            type="radio"
                            name="radio"
                            value={0}
                            variant="outline-danger"
                            checked={!publicMintingOpen}>
                          Off
                        </ToggleButton>
                      </ToggleButtonGroup>
                    </Row>
                    <WhitelistStatus/>
                    <RegularBold>Whitelist</RegularBold>
                    {whitelistAddresses.map((address) => (
                      <Regular key={address}>{address}</Regular>
                    ))}
                  </>
                )}
                {!whitelistMintingOpen && !publicMintingOpen && (
                  <>
                    <Header>Sale is not open yet!</Header>
                    <WhitelistStatus/>
                    <Regular>Come back later to mint some NFTs</Regular>
                    <WhitelistStatus/>
                  </>
                )}
                {whitelistMintingOpen && !publicMintingOpen && (
                  <>
                    <Header>Presale | {mintedNfts.toString()}/{presaleMaxNfts.toString()} minted</Header>
                    <Web3Status/>
                    {account && whitelistProof.length === 0 && (
                      <WhitelistStatus>Your address is not whitelisted for the presale, come back for the public sale!</WhitelistStatus>
                    )}
                    {account && whitelistProof.length !== 0 && (
                      <>
                        <WhitelistStatus>Your address is whitelisted! Go ahead and mint as many NFTs as you want</WhitelistStatus>
                        <MintBar
                          nftPrice={presalePrice}
                          handleMint={handlePresaleMint} />
                        {error && (
                          <ErrorText>{error}</ErrorText>
                        )}
                        <WhitelistStatus/>
                      </>
                    )}
                    {!account && (
                      <WhitelistStatus/>
                    )}
                  </>
                )}
                {publicMintingOpen && (
                  <>
                    <Header>Public Sale | {mintedNfts.toString()}/{maxNfts.toString()} minted</Header>
                    <Web3Status/>
                    <WhitelistStatus>Public sale is on. Go ahead and mint as many NFTs as you want</WhitelistStatus>
                    <MintBar
                      nftPrice={publicPrice}
                      handleMint={handleMint} />
                    {error && (
                      <ErrorText>{error}</ErrorText>
                    )}
                    <WhitelistStatus/>
                  </>
                )}
              </Col>
            </TextWrapper>
          </Container>
          </Web3ReactManager>
        </AppWrapper>
      </>
    );
}

export default App;
