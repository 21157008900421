import React, { useState } from 'react'
import styled from 'styled-components'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import { BigNumber } from '@ethersproject/bignumber'

const ONE_AVAX = BigNumber.from('1000000000000000000')

const ChangeButton = styled.button`
  background: #000000;
  font-family: Roboto Mono;
  font-style: normal;
  font-weight: bold;
  text-align: center;
  border: none;
  color: #FFF15D;
  border-radius: 12px 0px 0px 12px;
`

const VerticallyCentered = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const VerticallyCenteredRed = styled(VerticallyCentered)`
  background: #000000;
`

const NumberInput = styled.div`
  background: #000000;
  font-family: Roboto Mono;
  font-style: normal;
  font-weight: bold;
  text-align: center;
  border: none;
  color: #FFF15D;
  width: 100px;
`

const MintButton = styled(Button)`
  background: #000000;
  border-radius: 12px;
  width: 135px;
  border: none;
  :focus:not(:focus-visible) {
    outline: none;
    box-shadow: none;
    background-color: #818181;
  }
  &:hover {
    background-color: #818181;
  }
`

const ButtonText = styled.div`
  font-family: Roboto Mono;
  font-style: normal;
  font-weight: bold;
  text-align: center;
  text-transform: capitalize;
  color: #FFF15D;
`

const PriceText = styled.div`
  font-family: Roboto Mono;
  margin-left: 20px;
  font-style: normal;
  font-weight: bold;
  text-align: center;
  color: #000000;
`

interface MintBarProps {
  nftPrice: BigNumber
  handleMint: (numToMint: number, nftPrice: BigNumber) => void
}

export default function MintBar({nftPrice, handleMint}: MintBarProps) {
  const Max = 1000
  const Min = 1
  const [count, setCount] = useState(Min)
  const [ totalPrice, setTotalPrice ] = useState<BigNumber>(nftPrice)

  function handlePlus() {
    if (count < Max) {
      setCount(count + 1)
      setTotalPrice(BigNumber.from(count + 1).mul(nftPrice))
    }
  }

  function handleMinus() {
    if (count > Min) {
      setCount(count - 1);
      setTotalPrice(BigNumber.from(count - 1).mul(nftPrice))
    }
  }

  return (
    <Container fluid>
      <Row>
      <Col style={{display:'flex', justifyContent:'left', padding: '0'}}>
        <ChangeButton
          style={{ borderRadius: "12px 0px 0px 12px" }}
          onClick={handleMinus}
        >
          -
        </ChangeButton>
        <VerticallyCenteredRed>
          <NumberInput>{count}</NumberInput>
        </VerticallyCenteredRed>
        <ChangeButton
          style={{ borderRadius: "0px 12px 12px 0px" }}
          onClick={handlePlus}
        >
          +
        </ChangeButton>
        <div style={{width: "50px"}}/>
        <MintButton
          onClick={() => handleMint(count, nftPrice)}>
            <ButtonText>Mint</ButtonText>
        </MintButton>
        <VerticallyCentered>
          <PriceText>
            Total price: {(totalPrice.mul(100).div(ONE_AVAX).toNumber() / 100).toFixed(2)} ETH
          </PriceText>
        </VerticallyCentered>
     </Col>
     </Row>
    </Container>
  );
}
