import { UnsupportedChainIdError, useWeb3React } from '@web3-react/core'
import React from 'react'
import styled from 'styled-components'
import { WalletConnectConnector } from '@web3-react/walletconnect-connector'
import { NetworkContextName } from '../../constants'
import { injected } from '../../connectors'
import Button from 'react-bootstrap/Button'

const ButtonText = styled.div`
  font-family: Roboto Mono;
  font-style: normal;
  font-weight: bold;
  text-align: center;
  color: #FFF15D;
`

const Web3StatusGeneric = styled(Button)`
  height: 10%;
  align-items: center;
  border-radius: 12px;
  cursor: pointer;
  background: #000000;
  border: none;
  :focus:not(:focus-visible) {
    outline: none;
    box-shadow: none;
    background-color: #818181;
  }
  &:hover {
    background-color: #818181;
  }
`

function switchToEthereum() {
  injected.getProvider().then(provider => {
    provider
      .request({
        method: 'wallet_switchEthereumChain',
        params: [{chainId: '0x4'}]
      })
      .catch((error: any) => {
        console.log(error)
      })
  })
}

function Web3StatusInner() {
  const { account, connector, activate, deactivate, error } = useWeb3React()

  const tryActivation = async () => {
    if (connector instanceof WalletConnectConnector && connector.walletConnectProvider?.wc?.uri) {
      connector.walletConnectProvider = undefined
    }

    activate(connector ? connector : injected, undefined, true).catch(error => {
      if (error instanceof UnsupportedChainIdError) {
        activate(connector ? connector : injected)
      } else {
        console.log(error)
      }
    })
  }

  if (account) {
    return (
      <Web3StatusGeneric onClick={deactivate}>
        <ButtonText>{account.toLowerCase().slice(0, 6)}...{account.toLowerCase().slice(account.length-4)} | Logout</ButtonText>
      </Web3StatusGeneric>
    )
  } else if (error) {
    return (
      <Web3StatusGeneric onClick={switchToEthereum}>
        <ButtonText>{error instanceof UnsupportedChainIdError ? 'Switch to Ethereum network' : 'Error'}</ButtonText>
      </Web3StatusGeneric>
    )
  } else {
    return (
      <Web3StatusGeneric id="connect-wallet" onClick={tryActivation}>
        <ButtonText>Connect to Metamask wallet</ButtonText>
      </Web3StatusGeneric>
    )
  }
}

export default function Web3Status() {
  const { active } = useWeb3React()
  const contextNetwork = useWeb3React(NetworkContextName)

  if (!contextNetwork.active && !active) {
    return null
  }

  return (
    <Web3StatusInner />
  )
}
